export const defaultFiltersObject = () => ({
  searchValue: "",
  saleStatus: null,
  periodType: null,
  periodInclusion: null,
  dateRange: [],
  otas: [],
  shops: [],
  carClasses: []
})

export const defaultPlanObject = () => ({
  released: false,
  unreleasable: true,
  status: "not_published",
  code: null,
  name: null,
  type: "regular",
  sale_start: null,
  sale_end: null,
  sale_days: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    holiday: true
  },
  post_start: null,
  post_end: null,
  closing_out_type: "shop_hours",
  closing_out_options: {
    until_hour: null,
    days_before: null
  },
  companies_shop_ids: [],
  ota_ids: []
})
