import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractMutations from "./mutations"
import extractActions from "./actions"

const baseURI = "/companies/plans"

const store = new StoreItemsModule({ baseURI: baseURI })

store.mergeState({
  isDirty: false,
  item: {},
  initialItem: {}
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
