import { currentTimestamp } from "@/config/store/StoreItemsModule/helpers"
import { cloneDeep, map } from "lodash-es"
import { extractCommonMutations, extractInitialItemMutations } from "../../../shared"

const clonePriceRows = priceRows => {
  return map(priceRows, ({ car_class_id, prices }) => ({
    car_class_id,
    prices: { ...prices },
    _addedAt: `t_${currentTimestamp()}`
  }))
}

const emptyPriceSettingsObject = () => ({
  time_slot_type: null,
  price_keys: [],
  special_rates: false,
  basic_table: { price_rows: [] },
  special_tables: []
})

export const emptySpecialPriceTableObject = () => ({
  invalid: false,
  time_slot_id: null,
  time_slot_type: null,
  start_date: null,
  end_date: null,
  deleted_price_row_ids: [],
  price_rows: []
})

export default () => ({
  ...extractCommonMutations(),
  ...extractInitialItemMutations(),

  RESET_PRICE_SETTINGS: state => {
    state.item = { ...emptyPriceSettingsObject(), _reset: true }
  },

  ADD_BASIC_TABLE_PRICE_ROW: (state, item) => {
    item._addedAt = `t_${currentTimestamp()}`
    state.item.basic_table.price_rows.push(item)
  },

  SET_BASIC_TABLE(state, item) {
    state.item.basic_table = item
  },

  SET_BASIC_TABLE_PRICE_ROW_BY_INDEX(state, { item, index }) {
    state.item.basic_table.price_rows.splice(index, 1, item)
  },

  CLONE_BASIC_TABLE_PRICE_ROW: (state, index) => {
    const priceRow = state.item.basic_table.price_rows[index]
    const cloned = { ...cloneDeep(priceRow), id: null, car_class_id: null, _addedAt: `t_${currentTimestamp()}` }
    state.item.basic_table.price_rows.splice(index + 1, 0, cloned)
  },

  REMOVE_BASIC_TABLE_PRICE_ROW: (state, index) => {
    state.item.basic_table.price_rows.splice(index, 1)
  },

  ADD_SPECIAL_TABLE: state => {
    const item = emptySpecialPriceTableObject()
    item._addedAt = `t_${currentTimestamp()}`
    item.price_rows = clonePriceRows(state.item.basic_table.price_rows)
    state.item.special_tables.push(item)
  },

  SET_SPECIAL_TABLE_BY_INDEX(state, { item, index }) {
    state.item.special_tables.splice(index, 1, item)
  },

  ADD_SPECIAL_TABLE_PRICE_ROW: (state, { tableIndex, item }) => {
    item._addedAt = `t_${currentTimestamp()}`
    state.item.special_tables[tableIndex].price_rows.push(item)
  },

  SET_SPECIAL_TABLE_PRICE_ROW_BY_INDEX(state, { tableIndex, row: { index, item } }) {
    state.item.special_tables[tableIndex].price_rows.splice(index, 1, item)
  },

  REMOVE_SPECIAL_TABLE_PRICE_ROW: (state, { tableIndex, rowIndex }) => {
    state.item.special_tables[tableIndex].price_rows.splice(rowIndex, 1)
  },

  CLONE_SPECIAL_TABLE_PRICE_ROW: (state, { tableIndex, rowIndex }) => {
    const priceRow = state.item.special_tables[tableIndex].price_rows[rowIndex]
    const cloned = { ...cloneDeep(priceRow), id: null, car_class_id: null, _addedAt: `t_${currentTimestamp()}` }
    state.item.special_tables[tableIndex].price_rows.splice(rowIndex + 1, 0, cloned)
  },

  SET_SPECIAL_TABLE_BASIC_PRICE_ROWS: (state, index) => {
    state.item.special_tables[index].price_rows = clonePriceRows(state.item.basic_table.price_rows)
  },

  RESET_SPECIAL_TABLE_PRICE_ROWS: (state, index) => {
    state.item.special_tables[index].price_rows = []
  }
})
