import { api, i18n } from "@/config"
import { handleFailedResponse } from "@/helpers/common"
import { showSuccessToast } from "@/helpers/toasts"
import { cloneDeep } from "lodash-es"

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, id) => {
    try {
      const { data } = await api.get(`${baseURI}/${id}`)
      commit("SET_ITEM", data.data)
      commit("SET_INITIAL_ITEM", cloneDeep(data.data))
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  CREATE_ITEM: async ({ commit }, params) => {
    try {
      const { data } = await api.post(baseURI, { plan: params })
      showSuccessToast({ text: i18n.t("company_system.rate_plan_settings.plan_created") })
      commit("SET_ITEM", data.data)
      commit("SET_INITIAL_ITEM", cloneDeep(data.data))
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  },

  UPDATE_ITEM: async ({ commit }, { id, ...params }) => {
    try {
      const { data } = await api.put(`${baseURI}/${id}`, params)
      showSuccessToast({ text: i18n.t("company_system.rate_plan_settings.plan_updated") })
      commit("SET_ITEM", data.data)
      commit("SET_INITIAL_ITEM", cloneDeep(data.data))
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})
